.App {
  text-align: center;
}



h1{
  font-family:fantasy;
  font-size: 100px;
  color: red;
}
